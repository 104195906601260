// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:8cd2a819-679c-43b3-80e6-bd71d1355ffd",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": [],
        "passwordPolicyMinLength": 8
    },
    "oauth": {
        "redirectSignOut": "http://localhost:3000/,https://feature-continue.d11i7mg088dlx5.amplifyapp.com/,https://feature-continue.part.sandbox.imagesimilarity.tecalliance.net/,https://part.sandbox.imagesimilarity.tecalliance.net/",
        "domain": "jnp-auth-feature-continue.auth.eu-central-1.amazoncognito.com",
        "responseType": "code",
        "redirectSignIn": "http://localhost:3000/,https://feature-continue.d11i7mg088dlx5.amplifyapp.com/,https://feature-continue.part.sandbox.imagesimilarity.tecalliance.net/,https://part.sandbox.imagesimilarity.tecalliance.net/",
        "scope": [
            "openid",
            "profile",
            "email"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_user_pools_id": "eu-central-1_Q1lWFkBhX",
    "aws_user_pools_web_client_id": "4pias6u4le9tqecjvc0r4o3dml",
    "aws_cognito_region": "eu-central-1",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_custom_provider": "JNPfeaturecontinoAzureAD76ACA8D9",
    "aws_cognito_social_providers": [],
    "aws_appsync_apiKey": "da2-tfuvj4ie7neppojbllkdcrxl3a",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_graphqlEndpoint": "https://46mxkssvsvfxrhkzjdfc3f3xjm.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_user_files_s3_bucket": "jnp-feature-continue-fro-jnpfeaturecontinue8b8ed5-1l0a60igxnhkr",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "backend": {
        "region": "eu-central-1"
    },
    "amplify_appid": "d11i7mg088dlx5",
    "aws_project_region": "eu-central-1"
};

export default awsmobile;
